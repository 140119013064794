<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="row d-flex align-items-center">
          <div class="col-6 col-sm-6">
            <form class="search-form w-100" @submit.prevent="handlerSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search.search_keyword" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here by member name...">
              </div>
            </form>
          </div>
          <div class="col-2 col-sm-2">
            <button v-if="checkPermission.create" class="btn btn-primary btn-block" @click="handlerBtnCreate">Add New Member</button>
          </div>
        </div>
        <div class="mt-2" v-loading="loading.table">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Added Date</th>
                <th>Admin Status</th>
                <th>Agent Status</th>
                <th colspan="4">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in rows" :key="item.id">
                <td>{{ item.user.name }}</td>
                <td>{{ item.user.email }}</td>
                <td>{{ item.user.phone_number }}</td>
                <td>{{ item.added_at_str }}</td>
                <td>
                  <font-awesome :icon="item.is_admin ? 'check-circle' : 'times-circle'" :class="item.is_admin ? 'text-success' : 'text-danger'"></font-awesome>
                </td>
                <td>
                  <font-awesome :icon="item.user.agents.length ? 'check-circle' : 'times-circle'" :class="item.user.agents.length ? 'text-success' : 'text-danger'"></font-awesome>
                  <span v-if="item.user.agents.length"> {{ item.user.agents[0].workspace_name }}</span>
                </td>
                <td>
                  <button v-if="checkPermission.update" class="btn btn-outline-danger btn-sm" @click="handlerBtnDelete(item)" title="Remove member">
                    Kick
                  </button>
                  <button v-if="checkPermission.update" class="btn btn-outline-primary btn-sm ml-2" @click="handlerPromoteDemoteBtn(item)" title="Promote/Demote member">
                    {{ item.is_admin ? 'Demote from Admin' : 'Promote to Admin' }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty v-if="!total" description="No data"></el-empty>
          <b-pagination v-if="total > search.per_page" v-model="currentPage" :total-rows="total" :per-page="search.per_page"/>
        </div>
      </div>
    </div>
    <add-member :show="show_modal.create" @onHide="show_modal.create = false"/>
  </div>
</template>
<script>
import { isEqual } from 'lodash';
import moment from 'moment';

import divisionsApi from '../../../api/divisions';
import popupErrorMessages from '../../../library/popup-error-messages';
import AddMember from './AddMember.vue';

export default {
  name: 'DivisionsListMember',
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  components: {
    AddMember,
  },
  async mounted() {
    this.showLoader();
    await this.getList();
    this.hideLoader();
  },
  data() {
    return {
      loading: {
        table: false,
      },
      show_modal: {
        create: false,
      },
      search: {
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10,
        search_keyword: this.$route.query.search_keyword || '',
        search_by: this.$route.query.search_by || 'name',
        company_id: this.$store.state.company.activeCompany.id,
      },
      rows: [],
      total: 0,
      loaderStack: 0,
      loader: null,
      current_group: {},
      company_id: this.$store.state.company.activeCompany.id,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
    pageTitle() {
      return `Division Member - ${this.current_group.name || ''}`;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async getList() {
      this.loading.table = true;
      const options = {
        page: this.search.page.toString(),
        per_page: this.search.per_page.toString(),
        search_by: this.search.search_by,
        search_keyword: this.search.search_keyword,
        company_id: this.company_id,
      };
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      const response = await divisionsApi.memberList(this.$route.params.id, options);
      this.loading.table = false;
      popupErrorMessages(response);
      const { data } = response;
      this.rows = data.rows.map((v) => {
        v.added_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        return v;
      });
      this.current_group = data.member_group;
      this.total = data.count;
    },
    handlerBtnDelete(item) {
      this.$confirm(this.$t('divisions.confirm_remove_member'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const data = {
              groups_id: [item.member_group_id],
              users_id: [item.user_id],
            };
            const response = await divisionsApi.memberRemove(data);
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response).then(() => {
              this.$message({
                message: this.$t('divisions.success_remove_member'),
                type: 'success',
              });
              this.search.page = 1;
              this.search.search_keyword = '';
              this.getList();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    handlerSearch() {
      this.getList();
    },
    handlerBtnCreate() {
      this.show_modal.create = true;
    },
    handlerPromoteDemoteBtn(item) {
      const title = item.is_admin ? this.$t('divisions.confirm_demote_member') : this.$t('divisions.confirm_promote_member');
      this.$confirm(title, this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const data = {
              member_id: item.id,
              member_group_id: item.member_group_id,
              company_id: this.company_id,
            };
            const response = await divisionsApi.promoteDemoteAdmin(data, item.is_admin ? 'demote' : 'promote');
            await popupErrorMessages(response);
            this.$message({
              message: item.is_admin ? this.$t('divisions.success_demote_member') : this.$t('divisions.success_promote_member'),
              type: 'success',
            });
            this.search.page = 1;
            this.search.search_keyword = '';
            this.getList();
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
  },
};
</script>
